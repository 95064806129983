import React from 'react'
import { Routes, Route } from 'react-router'
import { useLocation } from "react-router-dom";

import NotFound from '../notFound/NotFound'
import Clock from '../clock/Clock'


const Router = (props) => {
    const location = useLocation();
    location.staticContext = props.staticContext;
    //console.log(location); 

    const seoLinks = [
        {
            'path': '/online-clock',
            'anchorText': 'Online Clock',
            'title': 'Online Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'Online Clock - exact time with seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'path': '/clock-tab',
            'anchorText': 'Clock Tab',
            'title': 'Clock Tab: Full Screen - Digital/Analog - Night mode',
            'description': 'Clock Tab - exact time with seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'path': '/online-clock-with-seconds',
            'anchorText': 'Online Clock with Seconds',
            'title': 'Online Clock with Seconds: Full Screen - Digital/Analog - Night mode',
            'description': 'Online Clock with Seconds - exact time with seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'path': '/clock-with-seconds',
            'anchorText': 'Clock with Seconds',
            'title': 'Clock with Seconds: Full Screen - Digital/Analog - Night mode',
            'description': 'Clock with Seconds - exact time with seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'path': '/digital-clock',
            'anchorText': 'Digital Clock',
            'title': 'Digital Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'Digital Clock - exact time with seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'path': '/analog-clock',
            'anchorText': 'Analog Clock',
            'title': 'Analog Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'Analog Clock - exact time with seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'path': '/time-clock',
            'anchorText': 'Time Clock',
            'title': 'Time Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'Time Clock - exact time with seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'path': '/flip-clock',
            'anchorText': 'Flip Clock',
            'title': 'Flip Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'Flip Clock - exact time with seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'path': '/world-clock',
            'anchorText': 'World Clock',
            'title': 'World Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'World Clock - exact time with seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'path': '/what-time-is-it',
            'anchorText': 'What Time is It',
            'title': 'What Time is It: Clock with Full Screen - Digital/Analog',
            'description': 'What Time is It - exact time with seconds on the full screen. Clock analogue or digital view switch.'
        },
        {
            'path': '/time-tab',
            'anchorText': 'Time Tab',
            'title': 'Time Tab: Full Screen - Digital/Analog - Night mode',
            'description': 'Time Tab - clock with exact time and seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'path': '/big-clock-online',
            'anchorText': 'Big Clock Online',
            'title': 'Big Clock Online: Full Screen - Digital/Analog - Night mode',
            'description': 'Big Clock Online - clock with exact time and seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'path': '/clock-live-with-seconds',
            'anchorText': 'Clock Live with Seconds',
            'title': 'Clock Live with Seconds: Full Screen - World Time - Night mode',
            'description': 'Clock Live with Seconds - exact time in clock live with seconds on the full screen. Night mode, world time and date for cities in all time zones.'
        },
        {
            'path': '/clock-live',
            'anchorText': 'Clock Live',
            'title': 'Clock Live: Full Screen - World Time Live - Night mode',
            'description': 'Clock Live - exact time in clock live on the full screen. Night mode, world time and date for cities in all time zones.'
        },

    ];

    return (
        <React.Fragment>
            <Routes>
                <Route exact path='/' element={<Clock {...props} location={location} seoLinks={seoLinks} />} />
                {seoLinks.map((seo, idx) => <Route key={idx} path={seo.path} element={<Clock {...props} location={location} seoLinks={seoLinks} />} />)}
                <Route path='*' element={<NotFound {...props} />} />
            </Routes>
        </React.Fragment>
    )
}

export default Router